<!--
 * @Description: 资质详情
 * @Author: huacong
 * @Date: 2021-06-10 16:52:23
 * @LastEditTime: 2021-12-22 14:56:09
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <div class="infoBoxBg">
      <section>
        <el-row>
          <el-col :span="24" class="colorFFF header">
            <p class="text-center title">{{ partData.title }}</p>
            <p class="text-right time">
              <span>
                分类：<a class="font-blue">{{
                  partData.category_text
                }}</a></span
              >
            </p>
            <p class="text-right time">
              <span> 发布人：小奕 </span>
              <span> 发布时间：{{ partData.push_time }} </span>
            </p>
            <div class="line"></div>
            <p class="text-right bottom">
              <!-- <span>分类：{{ partData.category }}</span>
              <span>地区：{{ partData.province }}-{{ partData.city }}</span> -->
            </p>
          </el-col>
        </el-row>
      </section>
    </div>
    <section>
      <div class="wenbenBox">
        <p style="margin-bottom:50px" v-html="partData.content"></p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "consultingDetail",
  data() {
    return {
      vipIcon: require("@/assets/icon/vip.png"),
      jobInfo: {},
      userInfo: {},
      comName: "",
      starIcon: "el-icon-star-off",
      starText: "收藏",
      starWorkId: "",
      partData: {},
    };
  },
  computed: {},
  methods: {
    getInfo() {
      let workId = this.$route.query.id;
      this.$http.post("index/home/getinfo", { id: workId }).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list[0];
        }
      });
      this.$http.post("index/home/addnumber", { id: workId }).then((res) => {
        if (res.data.resultCode === 1) {
          console.log("查看量+1");
        }
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>
<style scoped>
.contentBox {
  padding-top: 0;
}
.infoBoxBg {
  height: 200px;
  background: url(../../assets/img/infoBoxBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.colorFFF p {
  color: #fff;
}
.header .title {
  padding: 30px 0;
  font-size: 32px;
}
.header .line {
  height: 1px;
  background: #ffffff;
  margin: 10px 0;
}
.header .time span {
  margin-left: 24px;
}
.header .bottom span {
  margin-left: 24px;
}
.wenbenBox {
  background: #fff;
  padding: 20px;
  margin-top: 30px;
}
</style>
